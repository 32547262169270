import { useEffect } from "react";
import ChildSelect from "../../components/childSelect";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentChild } from "../../store/user";
import { fetchTransactions, selectTransactions } from "../../store/transactions";
import calendar from '../../img/calendartick.svg';

const BalancePage = () => {
    const {contract} = useSelector(selectCurrentChild);
    const transactions = useSelector(selectTransactions);
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(fetchTransactions(contract));
    },[dispatch,contract])

    return(
        <main className="container lessons-page">
            <h1 className="main-headline">Балансы</h1>
            <ChildSelect />
            {transactions.map(el=>{
                const year = el.date?.slice(0, 10).split("-")[0].slice(2,4)
                return(
                    <div className="transactions-list__item" key={el._id}>
                        <p className="transactions__desc">
                            <span>
                            {el.attendance ? 
                            'Списание':
                            'Пополнен.'
                            }
                            </span>
                        </p>
                        <div className="transactions-item__date">
                            <img alt="calendar icon" className="transactions-date__image" src={calendar} />
                            {el.date?.slice(4, 10).split("-").reverse().join('.')+year}
                        </div>
                        <div className={"transactions__amount "+ (el.attendance ? "" : "transactions__amount--positive")}>
                            {el.attendance ? 
                                el.amount :
                                "+"+el.amount
                            }
                            ₽
                        </div>
                    </div>
                )
            })}
        </main>
    )
}

export default BalancePage;